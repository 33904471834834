import HIHImage from '@/components/base/Image';
import { ImageMeta } from '@/shared/types/imageMeta';
import React from 'react';

interface ArticleCardProps {
  article: {
    coverImage: ImageMeta;
    title: string;
    shortDescription: string;
  };
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
  return (
    <article className="flex gap-x-3xl items-center">
      <HIHImage
        image={article.coverImage}
        width={100}
        height={100}
        className="rounded-sm object-cover w-[100px] h-[100px]"
      />
      <div>
        <p className="line-clamp-2 font-display text-headline-sm text-sys/surface/on-surface">
          {article.title}
        </p>
        <p className=" line-clamp-2 text-body-md text-sys/surface/on-surface-variant">
          {article.shortDescription}
        </p>
      </div>
    </article>
  );
};

export default ArticleCard;
