import { urlFor } from '@/sanity/sanityClient';
import Image from 'next/image';
import { ImageMeta } from '@/shared/types/imageMeta';

interface HIHImageProps {
  image: ImageMeta;
  alt?: string;
  className?: string;
  width: number;
  height: number;
  fill?: boolean;
  hasPlaceholder?: boolean;
  priority?: boolean;
  lazy?: boolean;
}

const HIHImage: React.FC<HIHImageProps> = ({
  image,
  className,
  width,
  height,
  alt,
  fill = false,
  priority = false,
  lazy = true,
}) => {
  if (!image || image == null || image.image == undefined) {
    return null;
  }

  const widthClass = `w-[${width}px]`;
  const heightClass = `h-[${height}px]`;

  if (fill) {
    return (
      <div className={`relative w-full h-full ${widthClass} ${heightClass}`}>
        <Image
          src={urlFor(image.image, width, height).url()}
          alt={alt ?? image.alt ?? ''}
          className={className}
          loading={lazy ? 'lazy' : 'eager'}
          fill
          priority={priority}
        />
      </div>
    );
  } else {
    return (
      <Image
        src={urlFor(image.image, width, height).url()}
        alt={alt ?? image.alt ?? ''}
        width={width}
        height={height}
        className={className}
        priority={priority}
        quality={100}
      />
    );
  }
};

export default HIHImage;
