'use client';

import HIHImage from '@/components/base/Image';
import { ARIA_LABEL_MAIN_NAV, SITE_NAME } from '@/shared/constants';
import React, { useState } from 'react';
import { ArticleCard } from '@/sanity/fetchArticles';
import { CategoryLink } from '@/sanity/fetchCategories';
import { Header as HeaderType } from '@/sanity/fetchLayout';
import NavigationMainMenu from './NavigationMainMenu';
import HIHLink from '@/components/base/Link';

function IconBurger() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
    >
      <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#1D1B20" />
    </svg>
  );
}

interface NavigationProps {
  header: HeaderType;
  articles?: ArticleCard[];
  categories?: CategoryLink[];
}

const Navigation: React.FC<NavigationProps> = ({ header, articles, categories }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const id = 'main-menu';

  return (
    <nav
      aria-label={ARIA_LABEL_MAIN_NAV}
      className="lg:flex lg:items-center lg:gap-x-7xl"
    >
      <div className="flex items-center">
        <HIHLink href="/" title="Startseite">
          <HIHImage
            image={header.logo}
            alt={`Logo ${SITE_NAME}, zur Startseite`}
            width={142}
            height={32}
            className="w-[139px] h-[56px] min-w-[139px] min-h-[56px]"
          />
        </HIHLink>

        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden ml-auto"
          aria-expanded={isMenuOpen}
          aria-controls={`#${id}`}
          aria-label={ARIA_LABEL_MAIN_NAV}
        >
          <IconBurger />
        </button>
      </div>

      <NavigationMainMenu
        id={id}
        appCta={header.appCta}
        menu={header.menu}
        articles={articles}
        categories={categories}
        isMenuOpen={isMenuOpen}
      />
    </nav>
  );
};

export default Navigation;
