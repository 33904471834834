import { cn } from '@/utils/cn';
import React from 'react';
import { ArticleCard } from '@/sanity/fetchArticles';
import { CategoryLink } from '@/sanity/fetchCategories';
import { Header as HeaderType } from '@/sanity/fetchLayout';
import NavigationSubMenu from './NavigationSubMenu';
import HIHButton from '@/components/base/Button';
import { ARIA_LABEL_NAVIGATION, URL_PAGE_APP } from '@/shared/constants';

interface NavigationMainMenuProps {
  appCta: HeaderType['appCta'];
  menu: HeaderType['menu'];
  articles?: ArticleCard[];
  categories?: CategoryLink[];
  isMenuOpen?: boolean;
  id: string;
}

const NavigationMainMenu: React.FC<NavigationMainMenuProps> = ({
  appCta,
  menu,
  articles,
  categories,
  isMenuOpen,
  id,
}) => {
  return (
    <ul
      id={id}
      className={cn(
        'hidden mt-3xl lg:mt-0 lg:flex lg:items-center lg:w-full gap-y-4xl lg:gap-y-0 lg:gap-x-4xl',
        isMenuOpen && 'block',
      )}
    >
      <div className="lg:hidden py-xs text-title-sm text-sys/surface/on-surface-variant">
        {ARIA_LABEL_NAVIGATION}
      </div>
      {menu.map((item) =>
        item.hasCategoriesDropdown ? (
          <NavigationSubMenu
            key={item.link}
            title={item.title}
            articles={articles}
            categories={categories}
          />
        ) : (
          <li key={item.link} className="py-lg lg:py-0">
            {/* a instead of nextjs/link because the latter has a known bug regarding focus on route change which is an a11y problem here in the navigation */}
            <a
              className="
              text-title-md text-sys/surface/on-surface
              rounded-full px-lg py-xs -ml-lg lg:ml-0
              motion-safe:transition-colors
              hover:bg-sys/surface/on-surface/8
              focus:bg-sys/secondary/surface/on-surface/12
              active:bg-sys/secondary/surface/on-surface/16
              focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-opacity-50
              "
              href={item.link}
            >
              {item.title}
            </a>
          </li>
        ),
      )}
      {appCta && (
        <li className="py-3xl lg:py-0 lg:ml-auto lg:[&_a]:whitespace-nowrap">
          <HIHButton
            link={URL_PAGE_APP}
            title={appCta.title}
            variant="secondary"
            size="sm"
          />
        </li>
      )}
    </ul>
  );
};

export default NavigationMainMenu;
