/* 
Interactions with the Sanity database. 
*/

import { isPreviewMode } from '@/utils/api-helpers';
import imageUrlBuilder from '@sanity/image-url';
import { createClient } from 'next-sanity';

// Create and configure a Sanity client
function sanityClient(useCdn = false) {
  return createClient({
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID!,
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
    apiVersion: '2024-02-09',
    useCdn: useCdn,
    perspective: isPreviewMode() ? 'previewDrafts' : 'published', // on preview mode, show drafts
    ignoreBrowserTokenWarning: isPreviewMode() ? true : false, // Ignore browser token warning in preview mode
    token: process.env.SANITY_API_TOKEN, // Use the API token to show private data
  });
}

// Fetch data from Sanity
export const fetchSanity = async ({
  query,
  params,
  useCdn,
}: {
  query: string;
  params?: Record<string, any>;
  useCdn?: boolean;
}) => {
  try {
    return await sanityClient(useCdn).fetch(query, params, {
      next: isPreviewMode()
        ? undefined
        : {
            revalidate: isPreviewMode() ? 0 : 120, // Revalidate cache in non-preview mode
          },
      cache: isPreviewMode() ? 'no-cache' : undefined, // Disable cache in preview mode
    });
  } catch (error) {
    console.error(error, query, params, useCdn);
    return undefined;
  }
};

// Initialize an sanity image URL builder to create image URLs
const builder = imageUrlBuilder(sanityClient());

// Create sanity image URLs with optional resizing
export function urlFor(source: any, width: number, height: number) {
  // No resizing for SVG images
  if (source.asset._ref.endsWith('svg')) {
    return builder.image(source);
  }

  return builder
    .image(source)
    .width(width || 2000)
    .height(height || 2000)
    .quality(30); // Quality set to 30 to reduce bandwidth usage
}

export function urlWithoutParamsFor(source: any) {
  return builder.image(source);
}
