export const SITE_NAME = 'Heute in Hamburg';

export const ARIA_LABEL_MAIN_NAV = 'Hauptmenü';
export const ARIA_LABEL_SUB_NAV = 'Untermenü, Themen und Artikel';
export const ARIA_LABEL_FOOTER_NAV = 'Fußzeile';
export const ARIA_LABEL_CATEGORIES_LIST = 'Themen';
export const ARIA_LABEL_NAVIGATION = 'Navigation';
export const ARIA_LABEL_CATEGORIES_LIST_LONG = 'Themenauswahl';
export const ARIA_LABEL_TOP_ARTICLES = 'Top-Artikel';
export const ARIA_LABEL_SOCIAL_LINKS = 'Heute in Hamburg in den sozialen Medien';
export const ARIA_LABEL_SKIP = 'Direkt zum Inhalt springen';
export const ARIA_LABEL_NEWSLETTER_EMAIL = 'E-Mail-Adresse';
export const ARIA_LABEL_NEWSLETTER_SUCCESS = 'Anmeldung zum Newsletter erfoglreich';
export const ARIA_LABEL_MODAL_CLOSE = 'Dialog schließen';

export const ERROR_NEWSLETTER_GENERIC =
  'Es ist ein Fehler aufgetreten. Bitte gib eine korrekte E-Mail-Adresse ein oder versuche es später erneut.';
export const ERROR_NEWSLETTER_EXISTS =
  'Diese E-Mail-Adresse ist bereits registriert oder noch nicht bestätigt. Bitte prüfe deinen Posteingang.';

export const CONTENT_TYPE_EVENT = 'event';
export const CONTENT_TYPE_ARTICLE = 'article';
export const CONTENT_TYPE_CATEGORY = 'category';
export const CONTENT_TYPE_PAGE = 'page';
export const CONTENT_TYPE_PUSH_MESSAGE = 'pushMessage';

export const URL_PATH_EVENT = 'event';
export const URL_PATH_ARTICLE = 'guide';
export const URL_PATH_CATEGORY = 'kategorie';

export const URL_PAGE_SOON = '/kalender';
export const URL_PAGE_ALWAYS = '/geht-immer';
export const URL_PAGE_APP = '/app';
export const URL_PAGE_TODAY = '/';

export const FCM_TOPIC = 'daily_push';
export const URL_PAGE_ERROR_GONE = '/error/410';

export const FCM_FALLBACK_TITLE = 'Keinen Plan?';
export const FCM_FALLBACK_MESSAGE =
  'Nicht mit uns! Dich erwartet Großes. 🥳 Klick dich durch unsere Empfehlungen für heute und plane auch direkt deine nächsten Wochen mit unserem Kalender. 📅';

export const EMPTY_GUIDES_TEXT =
  'Schade, momentan gibt es keine passenden Guides in dieser Kategorie. Aber schau dir gerne unsere Events an, um deine nächste Unternehmung zu planen.';

export const EMPTY_EVENTS_TEXT =
  'Schade, momentan gibt es keine passenden Events in dieser Kategorie. Aber schau dir gerne unsere Guides an, um unsere All-Time-Favorites zu entdecken.';
