import React from 'react';
import { ArticleCard as ArticleCardType } from '@/sanity/fetchArticles';
import ArticleCard from './ArticleCard';
import { cn } from '@/utils/cn';
import HIHLink from '@/components/base/Link';
import { URL_PATH_ARTICLE } from '@/shared/constants';

interface ArticlesListProps {
  articles: ArticleCardType[];
  isSubMenuOpen?: boolean;
  label: string;
  onArticleClick: () => void;
}

const ArticlesList: React.FC<ArticlesListProps> = ({
  isSubMenuOpen,
  articles,
  label,
  onArticleClick,
}) => {
  return (
    <ul
      aria-label={label}
      className="mt-3xl lg:mt-0 lg:basis-10/12 lg:columns-2 lg:gap-md"
    >
      {articles.map((article, i) => (
        <li
          key={article._id}
          className={cn(
            'py-lg lg:p-lg lg:break-inside-avoid-column',
            i > 2 && 'hidden lg:list-item',
          )}
        >
          <HIHLink
            onClick={onArticleClick}
            href={`/${URL_PATH_ARTICLE}/${article.slug.current}`}
            tabIndex={isSubMenuOpen ? 0 : -1}
          >
            <ArticleCard article={article} />
          </HIHLink>
        </li>
      ))}
    </ul>
  );
};

export default ArticlesList;
