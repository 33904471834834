/* 
Utility functions for handling event details, metadata generation, and location data. 
*/

import { EventDetail } from '@/sanity/fetchEventDetail';
import { urlFor } from '@/sanity/sanityClient';
import {
  CONTENT_TYPE_ARTICLE,
  CONTENT_TYPE_CATEGORY,
  CONTENT_TYPE_EVENT,
  CONTENT_TYPE_PAGE,
  URL_PATH_ARTICLE,
  URL_PATH_CATEGORY,
  URL_PATH_EVENT,
} from '@/shared/constants';
import { EventJsonLd } from '@/shared/types/eventData';
import { LocationData } from '@/shared/types/location';
import { formatISO, isAfter, parseISO, startOfDay } from 'date-fns';

// Determine if the preview mode is active (used on preview environment preview.heuteinhamburg.de)
export function isPreviewMode() {
  return process.env.NEXT_PUBLIC_SANITY_PREVIEW_MODE === 'true';
}

// Generate default metadata for SEO and social sharing
export function generateDefaultMetadata(data: any) {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL ?? '';

  return {
    metadataBase: new URL(siteUrl),
    title: data.seo?.metaTitle || data.title,
    description: data.seo?.metaDescription || data.shortDescription || '',
    openGraph: {
      title: data.seo?.socialTitle || data.title,
      images: [
        {
          url: data.coverImage?.image
            ? urlFor(data.coverImage?.image, 1200, 630).url()
            : '/images/sharing.png', // Fallback image in the public folder
          width: 1200,
          height: 630,
          alt: data.coverImage?.alt || 'Heute in Hamburg', // Provide a default alt text
        },
      ],
    },
    robots: {
      index: data.seo?.hideFromSearchEngines ? false : true,
      follow: data.seo?.hideFromSearchEngines ? false : true,
    },
  };
}

// Generate JSON-LD schema for future events for Google
export function generateFutureEventsJsonLd(event: EventDetail): EventJsonLd[] {
  const currentDate = startOfDay(new Date());

  const futureEventDates = event.eventDates
    ? event.eventDates?.filter((eventDate) => {
        const startDate = formatISO(eventDate.startDate);
        return isAfter(startDate, currentDate);
      })
    : [];

  return futureEventDates.map((eventDate) => {
    let startDateISO;
    let endDateISO;

    if (event.startTime) {
      startDateISO = formatISO(parseISO(`${eventDate.startDate}T${event.startTime}:00`));
    }

    if (event.endTime) {
      endDateISO = formatISO(
        parseISO(
          `${eventDate.endDate ? eventDate.endDate : eventDate.startDate}T${event.endTime}:00`,
        ),
      );
    }

    const jsonLd: EventJsonLd = {
      '@context': 'https://schema.org',
      '@type': 'Event',
      name: event.name,
      ...(startDateISO && { startDate: startDateISO }),
      ...(endDateISO && { endDate: endDateISO }),
      location: {
        '@type': 'Place',
        name: event.location?.name,
        ...(event.location?.address && {
          address: {
            '@type': 'PostalAddress',
            streetAddress: event.location.address,
          },
        }),
        ...(event.location?.geopoint && {
          geo: {
            '@type': 'GeoCoordinates',
            latitude: event.location?.geopoint.lat,
            longitude: event.location?.geopoint.lng,
          },
        }),
      },
      image: [urlFor(event.coverImage?.image, 1200, 628).url()],
      description: event.shortDescription,
      offers: {
        '@type': 'Offer',
        ...(event.ticketLink && { offersUrl: event.ticketLink }),
        priceCurrency: 'EUR',
        ...(startDateISO && { validFrom: startDateISO }),
      },
    };

    return jsonLd;
  });
}

// Convert content type to corresponding URL path
export function contentTypeToPath(contentType: string): string {
  const contentTypeMap = new Map<string, string>([
    [CONTENT_TYPE_PAGE, ''],
    [CONTENT_TYPE_EVENT, URL_PATH_EVENT],
    [CONTENT_TYPE_ARTICLE, URL_PATH_ARTICLE],
    [CONTENT_TYPE_CATEGORY, URL_PATH_CATEGORY],
  ]);

  return contentTypeMap.get(contentType) || '';
}

// Generate a Google Maps link for the event location
export const generateGoogleMapsLink = (location?: LocationData) => {
  if (!location) {
    return undefined;
  }

  let geoString = '';
  let addressString = '';

  const lat = location?.geopoint?.lat;
  const lng = location?.geopoint?.lng;
  const address = location?.address;

  if (lat && lng) {
    geoString = `&ll${lat},${lng}`;
  }

  if (address) {
    addressString = `,${location.address}`;
  }

  return `https://maps.google.com/?q=${location.name}${addressString}${geoString}`;
};
