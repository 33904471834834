import React from 'react';
import { CategoryLink } from '@/sanity/fetchCategories';
import { URL_PATH_CATEGORY } from '@/shared/constants';
import HIHLink from '@/components/base/Link';

interface NavigationCategoriesListProps {
  categories: CategoryLink[];
  isSubMenuOpen?: boolean;
  labelledBy: string;
  onCategoryClick: () => void;
}

const NavigationCategoriesList: React.FC<NavigationCategoriesListProps> = ({
  isSubMenuOpen,
  categories,
  labelledBy,
  onCategoryClick,
}) => {
  return (
    <ul aria-labelledby={labelledBy} className="mt-lg">
      {categories.slice(0, 7).map((category) => (
        <li key={category._id} className="py-lg">
          <HIHLink
            onClick={onCategoryClick}
            href={`/${URL_PATH_CATEGORY}/${category.slug.current}`}
            className="
        text-title-md text-sys/surface/on-surface
        rounded-full px-lg py-xs -ml-lg lg:ml-0
        motion-safe:transition-colors
        hover:bg-sys/surface/on-surface/8
        focus:bg-sys/surface/on-surface/12
        active:bg-sys/surface/on-surface/16
      "
            tabIndex={isSubMenuOpen ? 0 : -1}
          >
            {category.name}
          </HIHLink>
        </li>
      ))}
    </ul>
  );
};

export default NavigationCategoriesList;
